<template>
    <v-row class="ma-0 pa-0" justify="space-around">
        <!-- <v-col class="ma-0 pa-0" cols="auto">
            <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col> -->
        <v-col cols="12" >
            <GChart
                type="PieChart"
                :options="options"
                :data="data"></GChart>
        </v-col>
    </v-row>
</template>

<script>
    import { GChart } from "vue-google-charts/legacy";
    export default {
        name: 'PredictionPieChart',
        props: [
            'statistic_title_name',
            'value',
            'link'
        ],
        components: {
            GChart
        },
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
            data: [
                ['Threat','Quantity'],
                ['Threat 1', 50],
                ['Threat 2', 40],
                ['Threat 3', 60],
                ['Threat 4', 55],
                ['Threat 5', 60],
            ],
            options: {
                "pieHole": 0.5,
                "legend": { "position": "none" },
                "chartArea": {"margin":"auto auto", "top": 0, "bottom": 0, "left": 0, "right": 0},
                "colors": [
                    "#FF6F61", "#FFD166", "#06D6A0", "#118AB2", "#FF7F0E", "#8338EC", "#FF5E78", "#64C4ED", "#E5989B", "#D8A7B1",
                    "#72DDF7", "#426E86", "#C94C4C", "#FAE03C", "#74C69D", "#5975F0", "#FF5733", "#01BAEF", "#AB87FF", "#FFC312",
                    "#B33771", "#F79F1F", "#50A684", "#7FB800", "#5F0F40", "#2A6FB6", "#F4A896", "#5E2B8A", "#5C946E", "#FFADAD",
                    "#C8F7C5", "#FFD700", "#00818A", "#D5F4E6", "#2E294E", "#BA3D49", "#135058", "#FDFFD9", "#A3D2CA", "#C9B1BD",
                    "#FF6F61", "#FFD166", "#06D6A0", "#118AB2", "#FF7F0E", "#8338EC", "#FF5E78", "#64C4ED", "#E5989B", "#D8A7B1",
                    "#72DDF7", "#426E86", "#C94C4C", "#FAE03C", "#74C69D", "#5975F0", "#FF5733", "#01BAEF", "#AB87FF", "#FFC312",
                    "#B33771", "#F79F1F", "#50A684", "#7FB800", "#5F0F40", "#2A6FB6", "#F4A896", "#5E2B8A", "#5C946E", "#FFADAD",
                    "#C8F7C5", "#FFD700", "#00818A", "#D5F4E6", "#2E294E", "#BA3D49", "#135058", "#FDFFD9", "#A3D2CA", "#C9B1BD"
                ],
                "fontName": "Poppins",
                "title": {
                    "position": "center"
                },
                "tooltip": {
                    "isHtml": true
                }
            }
        }),
        watch: {
            value: function(){
                if(this.value != null){
                    this.data = this.value;
                }
            }
        },
        mounted(){
            if(this.value != null){
                this.data = this.value;
            }
        },
        methods: {
            open_further_details: function(){
                if(this.link){
                    this.$router.replace(this.link);
                }
            }
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Poppins" !important;
        font-weight: 600;
        font-size: 12pt !important;
        line-height: 40px;

        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>